<template>
	<div>
		<div class="top">
			<el-steps :active="active" align-center>
				<el-step title="商户信息" description=""></el-step>
				<el-step title="经营者信息" description=""></el-step>
				<el-step title="结算账户" description=""></el-step>
				<el-step title="提交审核" description=""></el-step>
			</el-steps>
		</div>
		<p style="height: 10px; background-color: #f6f6f6; margin-top: 20px; margin-bottom: 0px"></p>
		<van-form v-show="active == 1" ref="form_step1" @failed="onFailed">
			<van-cell-group>
				<van-field name="uploader" label="*商户图标">
					<template #input>
						<van-uploader class="user_logo" :after-read="afterReadLogo" v-model="fileListLogo" :preview-image="false" :max-size="500 * 1024" @oversize="onOversize"><img :src="logo" style="height: 24px; width: 24px" /> </van-uploader>
					</template>
				</van-field>

				<van-field v-model="params[0].merName" name="商户全称" label="*商户全称" placeholder="商户全称" :rules="[{ required: true, message: '请填写商户全称' }]" />

				<van-field v-model="merValue" is-link readonly label="*商户类型" placeholder="选择商户类型" @click="showMerPicker = true" />
				<van-field v-model="params[0].merAddress" name="商户地址" label="*商户地址" placeholder="商户地址" :rules="[{ required: true, message: '请填写商户地址' }]" />
				<van-field v-model="merchantName" name="上级商户" label="上级商户" :disabled="disable_text" contenteditable="false" placeholder="" />
				<p style="height: 10px; background-color: #f6f6f6; margin-top: 0px"></p>
				<van-field v-model="merCertTypeValue" is-link readonly label="*商户证件类型" placeholder="选择商户证件类型" @click="showMerCertTypePicker = true" />
				<van-field v-model="params[0].companyName" name="商户证件名称:" label="*商户证件名称" placeholder="商户证件名称" :rules="[{ required: true, message: '请填写商户证件名称' }]" />
				<van-field v-model="params[0].companyCertNo" name="商户证件代码:" label="*商户证件代码" placeholder="商户证件代码" :rules="[{ required: true, message: '请填写商户证件代码' }]" />

				<van-field name="uploader" label="*商户证件照片">
					<template #input>
						<van-uploader :after-read="afterRead" v-model="fileListCert" :preview-image="false" :max-size="500 * 1024" @oversize="onOversize">
							<p style="height: 30px; background-color: transparent; margin-top: 0px"></p>
							<div style="width: 288px; margin-left: calc(0px - 4.2em - 12px)">
								<img :src="cert" style="height: 180px; width: 288px" />
							</div>
						</van-uploader>
					</template>
				</van-field>
			</van-cell-group>
		</van-form>
		<van-form @submit="onSubmit" v-show="active == 2" ref="form_step2" @failed="onFailed">
			<van-cell-group>
				<van-field v-model="params[1].merContactName" name="法人/经营者姓名" label="*法人/经营者姓名" placeholder="请输入法人/经营者姓名" :rules="[{ required: true, message: '请填写法人/经营者姓名' }]" />
				<van-field v-model="certTypeValue" is-link readonly label="*证件类型" placeholder="选择证件类型" @click="showCertPicker = true" style="margin-top: -10px" />
				<van-field v-model="params[1].certificateNo" name="证件号码" label="*证件号码" placeholder="证件号码" :rules="[{ required: true, message: '请填写证件号码' }]" />
				<van-field v-model="params[1].merContactPhone" name="联系电话" label="*联系电话" placeholder="联系电话" :rules="[{ required: true, message: '请填写联系电话' }]" />
				<p style="height: 1px; background-color: transparent; margin-top: 0px"></p>

				<van-field name="uploader" label="*证件照片（正面）">
					<template #input>
						<van-uploader :after-read="afterReadCerts" v-model="fileListIdCertFront" :preview-image="false" :max-size="500 * 1024" @oversize="onOversize">
							<p style="height: 30px; background-color: transparent; margin-top: 0px"></p>
							<div style="height: 180px; width: 50vw; background-color: transparent; margin-top: 50px"></div>
						</van-uploader>
						<div style="width: 144px; margin-left: calc(0px - 64vw - 12px)">
							<img :src="certFront" style="height: 180px; width: 288px" />
						</div>
					</template>
				</van-field>
				<van-field name="uploader" label="*证件照片（背面)">
					<template #input>
						<van-uploader :after-read="afterReadCerts" v-model="fileListIdCertBack" :preview-image="false" :max-size="500 * 1024" @oversize="onOversize">
							<p style="height: 30px; background-color: transparent; margin-top: 0px"></p>
							<div style="height: 180px; width: 50vw; background-color: transparent; margin-top: 50px"></div>
						</van-uploader>
						<div style="width: 144px; margin-left: calc(0px - 64vw - 12px)">
							<img :src="certBack" style="height: 180px; width: 288px; margin-top: 12px" />
						</div>
					</template>
				</van-field>
				<div style="margin: 16px; color: #a1a1a1; font-size: 11px; height: 44px">说明： 请填写营业执照/统一社会信用证代码证书/个体工商户营业执照上的经营者/法定代表人姓名。</div>
			</van-cell-group>
		</van-form>
		<van-form @submit="onSubmit" v-show="active == 3" ref="form_step3" @failed="onFailed">
			<van-cell-group>
				<van-field v-model="params[2].acctName" name="开户名称" label="*开户名称" placeholder="开户名称" :rules="[{ required: true, message: '请填写开户名称' }]" />
				<van-field v-model="acctBank" is-link readonly label="*开户银行" placeholder="选择开户银行" @click="showBankPicker = true" style="margin-top: 0px" />
				<van-field v-model="acctBankCardType" is-link readonly label="*开户类型" placeholder="选择开户类型" @click="showBankCardTypePicker = true" style="margin-top: 0px" />
				<van-field v-model="params[2].acctNo" name="银行账号" label="*银行账号" placeholder="银行账号" :rules="[{ required: true, message: '请填写银行账号' }]" />
				<van-field v-model="params[2].acctPhone" name="预留手机号" label="*预留手机号" placeholder="预留手机号" :rules="[{ required: true, message: '请填写预留手机号' }]" />

				<van-field name="uploader" label="*辅助证明材料">
					<template #input>
						<van-uploader :after-read="afterReadProf" v-model="fileListProf" :preview-image="false" :max-size="500 * 1024" @oversize="onOversize">
							<p style="height: 30px; background-color: transparent; margin-top: 0px"></p>
							<div style="width: 288px; margin-left: calc(0px - 4.2em - 12px)">
								<img :src="prof" style="height: 180px; width: 288px" />
							</div>
						</van-uploader>
					</template>
				</van-field>
				<div style="margin: 16px; color: #a1a1a1; font-size: 11px; height: 84px">说明： 1、对公账户请上传开户证明；借记卡需要上传银行卡照片或经营场所证明（包括但不限于产权证明，租赁合同，或集体经营场所证明）2、如果是个体工商户，收款户名必须与营业执照上的”经营者姓名“一致。如果是企业，收款户名必须与营业执照上的“企业名称”一致。</div>

				<p style="height: 10px; background-color: #f6f6f6; margin-top: 10px"></p>
			</van-cell-group>
		</van-form>
		<van-form @submit="onSubmit" v-show="active == 4" ref="form_step4" @failed="onFailed">
			<div class="tips">
				<div class="tip-text"><img src="@/assets/image/apply/icon_tips.png" />&emsp;温馨提示: 提交申请后，申请信息不可修改，请您确认。</div>
			</div>
			<p style="height: 1px; background-color: transparent; margin-top: 12px"></p>
		</van-form>

		<div>
			<div style="margin: 16px" v-show="active > 1">
				<van-button round block type="primary" native-type="submit" @click.native="prevStep"> 上一步 </van-button>
			</div>
			<div style="margin: 16px">
				<van-button round block type="primary" @click.native="nextStep">
					{{ active < 4 ? '下一步' : '提交申请' }}
				</van-button>
			</div>
		</div>
		<van-popup v-model:show="showTempPicker" round position="bottom" style="padding: 0px">
			<van-picker :columns="temples" ref="temp_picker" @cancel="showTempPicker = false" @change="onComfirmTemple()" />
		</van-popup>
		<van-popup v-model:show="showCertPicker" round position="bottom">
			<van-picker :columns="certTypeNames" ref="cert_picker" @cancel="showCertPicker = false" @change="onComfirmCertType()" />
		</van-popup>
		<van-popup v-model:show="showMerPicker" round position="bottom">
			<van-picker :columns="merTypes" ref="picker" @cancel="showMerPicker = false" @change="onComfirmMerType()" />
		</van-popup>
		<van-popup v-model:show="showBankPicker" round position="bottom">
			<van-picker :columns="banks" ref="bank_picker" @cancel="showBankPicker = false" @change="onComfirmBank()" />
		</van-popup>
		<van-popup v-model:show="showBankCardTypePicker" round position="bottom">
			<van-picker :columns="cardTypes" ref="cardtype_picker" @cancel="showBankCardTypePicker = false" @change="onComfirmCardType()" />
		</van-popup>
		<van-popup v-model:show="showMerCertTypePicker" round position="bottom">
			<van-picker :columns="merCertTypes" ref="mercert_picker" @cancel="showMerCertTypePicker = false" @change="onComfirmMerCertType()" />
		</van-popup>
	</div>
</template>

<script>
	import { uuid } from 'vue-uuid'
	import OSS from 'ali-oss'
	import { Dialog } from 'vant'

	export default {
		name: 'apply',
		data() {
			return {
				active: 1,
				disable_text: true,
				merchantName: '',
				merValue: '',
				merFullName: '',
				merShortName: '',
				merAddress: '',
				usccNumber: '', //统一信用代码
				bank: '',
				temple: '',
				draftId: '', //"35529e81-de12-4c94-92c9-4418ddda87a3",
				certTypeValue: '身份证',
				merTypeRadio: '2',
				cert: require('@/assets/image/apply/bg_cert.png'),
				certFront: require('@/assets/image/apply/icon_certfront.png'),
				certBack: require('@/assets/image/apply/icon_certback.png'),
				certRemoteUri: '',
				certFrontRemoteUri: '',
				certBackRemoteUri: '',
				logoRemoteUri: '',
				profRemoteUri: '',
				logo: require('@/assets/image/apply/icon_merlogo.png'),
				prof: require('@/assets/image/apply/icon_prof.png'),
				fileListLogo: [],
				logoFile: '',
				certFile: '',
				fileListCert: [],
				fileListIdCertFront: [],
				fileListIdCertBack: [],
				fileListProf: [],
				showMerPicker: false,
				showCertPicker: false,
				showTempPicker: false,
				showBankPicker: false,
				showMerCertTypePicker: false,
				showBankCardTypePicker: false,

				acctBankCardType: '',
				merCertTypeValue: '',
				// U: 营业执照，Y: 组织机构代码证，V: 统一社会信用代码
				merCertTypes: [
					{
						type: 'U',
						text: '营业执照'
					},
					{
						type: 'Y',
						text: '组织机构代码证'
					},
					{
						type: 'V',
						text: '统一社会信用代码'
					}
				],
				banks: [],
				merTypes: [{ text: '企业' }, { text: '个体工商户' }],
				cardTypes: [
					{ text: '', type: '' },
					{ text: '借记卡', type: '401' },
					{ text: '企业户', type: '601' }
				],
				// {text: "二类户", type: "701"}, {
				//     text: "三类户",
				//     type: "702"
				//   }
				cerTypes: ['', 'I', 'G', 'P', 'U'],
				certTypeNames: ['', '身份证', '军官证', '护照', '其他'],
				temples: [{ text: '' }],
				// 绑定列表

				bucketName: 'heshangjr-development',
				regionName: 'oss-cn-hangzhou',
				// 参数列表
				params: [
					{
						companyCertNo: '',
						companyCertPhoto: '',
						companyCertType: '',
						companyName: '',
						merAddress: '',
						merLogo: '',
						merName: '',
						merType: '2',
						parentId: '',
						draftId: ''
					},
					{
						certificateBackPhoto: '',
						certificateFrontPhoto: '',
						certificateNo: '',
						certificateType: 'I',
						draftId: '',
						merContactName: '',
						merContactPhone: ''
					},
					{
						acctName: '',
						acctBankId: '',
						acctNo: '',
						acctPhone: '',
						acctCardType: '',
						auxiliaryMaterialsPhoto: '',
						auxiliaryMaterialsPhotoUrl: '',
						draftId: ''
					},
					{
						type: 2,
						draftId: ''
					}
				],
				//
				acctBank: '',
				sts: '',
				// 四个步骤需要全部成功
				success_step_1: 'true',
				success_step_2: 'false',
				success_step_3: 'false',
				success_step_4: 'false',
				templeId: ''
			}
		},
		methods: {
			onFailed() {
				// console.log("onFailed")
				this.success_step_1 = 'false'
			},
			onOversize(file) {
				this.$message.error('图片大小不能超过 500kb')
			},

			submitData(step, direction) {
				if (this.success_step_1 == 'false') {
					this.success_step_1 = 'true'
					return
				}

				let request_uri = '/frontend/merchant/applyStep' + step
				let params = this.params[this.active - 1]
				params.draftId = this.draftId
				// console.log(params)
				let _this = this
				Http.post(request_uri, params)
					.then(result => {
						if (result.code === 200) {
							// console.log("submit success:")
							// console.log(result.data)
							_this.changeStatus(step, true)

							if (direction == 'next') {
								if (this.active == 4) {
									// 进入审核中状态
									localStorage.setItem('merchantDraftStatus', '2')
									// 进入等待审核页面
									_this.$router.push({ path: '/h5/applying' })
									// 删除本地的draftId
									// setTimeout(() => {
									//   localStorage.removeItem('draftId')
									// }, 500)
								} else {
									this.active = this.active + 1
									this.success_step_1 = 'true'
								}
							} else {
								if (direction == 'prev') {
									this.success_step_1 = 'true'
									this.active = this.active - 1
								}
							}
							this.getCurrentStepData() // 提交结束 重新获取一遍数据
						} else {
							_this.changeStatus(step, false)
							_this.$message.error(result.message || '提交失败！')
						}
						_this.fullscreenLoading = false
					})
					.catch(error => {
						// console.log(error)
						_this.$message.error('请求失败')
						if (direction == 'prev') {
							_this.active = _this.active - 1
						}
						_this.fullscreenLoading = false
					})
					.finally(() => {
						_this.fullscreenLoading = false
					})
			},
			changeStatus(step, status) {
				switch (step) {
					case 1:
						this.success_step_1 = status
						break
					case 2:
						this.success_step_2 = status
						break
					case 3:
						this.success_step_3 = status
						break
					case 4:
						this.success_step_4 = status
						break
					default:
						break
				}
			},
			saveCurrentStep() {},
			prevStep() {
				// this.submitData(this.active, "prev")
				this.getCurrentStepData()
				this.active = this.active - 1
			},
			nextStep() {
				switch (this.active) {
					case 1:
						this.$refs.form_step1.submit()
						if (this.params[0].companyCertPhoto == '' || this.params[0].merLogo == '') {
							this.success_step_1 = 'false'
							this.$message.error('请上传头像和资质证明图片')
						}
						break
					case 2:
						this.$refs.form_step2.submit()
						if (this.params[1].certificateFrontPhoto == '' || this.params[1].certificateBackPhoto == '') {
							this.success_step_1 = 'false'
							this.$message.error('身份证照片需要上传正反两面')
						}
						if (this.params[1].certificateType == '') {
							this.success_step_1 = 'false'
							this.$message.error('需要选择证件类型')
						}
						break
					case 3:
						this.$refs.form_step3.submit()

						break

					default:
						break
				}
				let _this = this
				_this.fullscreenLoading = true
				setTimeout(function () {
					_this.submitData(_this.active, 'next')
				}, 1000)
			},
			afterReadProf(file) {
				this.prof = window.URL.createObjectURL(file.file)
				//调用图片上传
				if (this.profRemoteUri == '') {
					this.profRemoteUri = uuid.v4() + '.jpg'
				}
				// this.fileListProf

				this.uploadFile(file.file, this.profRemoteUri)
				this.params[2].auxiliaryMaterialsPhoto = this.profRemoteUri
			},
			afterReadLogo(file) {
				this.logoFile = file
				this.logo = window.URL.createObjectURL(file.file)
				//调用图片上传
				if (this.logoRemoteUri == '') {
					this.logoRemoteUri = uuid.v4() + '.jpg'
				}

				this.uploadFile(file.file, this.logoRemoteUri)
				this.params[0].merLogo = this.logoRemoteUri

				// console.log(this.logoFile.file)
			},
			afterReadCerts(file) {
				let frontFile = null
				let backFile = null

				if (this.fileListIdCertFront.slice(-1).length > 0) {
					frontFile = this.fileListIdCertFront.slice(-1)[0].file
					this.certFront = window.URL.createObjectURL(frontFile)
				} else {
					this.certFront = require('@/assets/image/apply/icon_certfront.png')
				}

				if (this.fileListIdCertBack.slice(-1).length > 0) {
					backFile = this.fileListIdCertBack.slice(-1)[0].file
					this.certBack = window.URL.createObjectURL(backFile)
				} else {
					this.certBack = require('@/assets/image/apply/icon_certback.png')
				}
				// 只保留一个结果
				if (this.fileListIdCertFront.length > 1) {
					this.fileListIdCertFront.shift()
				}
				if (this.fileListIdCertBack.length > 1) {
					this.fileListIdCertBack.shift()
				}
				// console.log(backFile)
				// console.log(this.certFront)
				// console.log(this.certBack)

				//调用图片上传 图片名固定 设置x-oss-forbid-overwrite 为 false
				if (this.certBackRemoteUri == '') {
					this.certBackRemoteUri = uuid.v4() + '.jpg'
				}
				if (this.certFrontRemoteUri == '') {
					this.certFrontRemoteUri = uuid.v4() + '.jpg'
				}
				this.params[1].certificateBackPhoto = ''
				this.params[1].certificateFrontPhoto = ''
				if (frontFile && backFile) {
					this.uploadFile(frontFile, this.certFrontRemoteUri)
					this.uploadFile(backFile, this.certBackRemoteUri)
					this.params[1].certificateBackPhoto = this.certBackRemoteUri
					this.params[1].certificateFrontPhoto = this.certFrontRemoteUri
				}
			},
			afterRead(file) {
				this.certFile = file
				this.cert = window.URL.createObjectURL(file.file)
				// console.log(this.certFile)
				if (this.certRemoteUri == '') {
					this.certRemoteUri = uuid.v4() + '.jpg'
				}
				this.uploadFile(file.file, this.certRemoteUri)
				this.params[0].companyCertPhoto = this.certRemoteUri
			},
			onComfirmBank(value) {
				this.acctBank = this.banks[this.$refs.bank_picker.getIndexes()[0]].text
				this.params[2].acctBankId = this.banks[this.$refs.bank_picker.getIndexes()[0]].id
			},
			onComfirmCardType() {
				this.acctBankCardType = this.cardTypes[this.$refs.cardtype_picker.getIndexes()[0]].text
				this.params[2].acctCardType = this.cardTypes[this.$refs.cardtype_picker.getIndexes()[0]].type
			},
			onComfirmTemple(value) {
				let idx = this.$refs.temp_picker.getIndexes()[0]
				this.temple = this.temples[idx].text
				this.params[3].parentId = this.temples[idx].id
			},
			onComfirmCertType(value) {
				let certIndex = this.$refs.cert_picker.getIndexes()[0]
				if (certIndex < 5) {
					this.params[1].certificateType = this.cerTypes[certIndex]
					this.certTypeValue = this.certTypeNames[certIndex]
				}
			},
			onComfirmMerType(value) {
				this.merType = this.$refs.picker.getIndexes()[0]
				// console.log(this.merType)
				this.merValue = this.merTypes[this.merType].text
				this.params[0].merType = this.merType + 2
			},
			onComfirmMerCertType() {
				let idx = this.$refs.mercert_picker.getIndexes()[0]
				this.merCertTypeValue = this.merCertTypes[idx].text
				this.params[0].companyCertType = this.merCertTypes[idx].type
			},

			merTypeRadioClicked() {
				// console.log(this.merTypeRadio)
				this.params[3].type = this.merTypeRadio
			},
			onSubmit() {
				//
				// console.log("trigger onSubmit")
			},
			uploadFile(file, fileName) {
				const headers = {
					// 指定该Object被下载时网页的缓存行为。
					'Cache-Control': 'no-cache',
					// 指定该Object被下载时的名称。
					// 'Content-Disposition': 'oss_download.txt',
					// 指定该Object被下载时的内容编码格式。
					// 'Content-Encoding': 'UTF-8',
					// 指定过期时间。
					// 'Expires': 'Wed, 08 Jul 2022 16:57:01 GMT',
					// 指定Object的存储类型。
					// 'x-oss-storage-class': 'Standard',
					// 指定Object的访问权限。
					'x-oss-object-acl': 'private',
					// 设置Object的标签，可同时设置多个标签。
					// 'x-oss-tagging': 'Tag1=1&Tag2=2',
					// 指定CopyObject操作时是否覆盖同名目标Object。此处设置为true，表示禁止覆盖同名Object。
					'x-oss-forbid-overwrite': 'false'
				}
				let data = file
				const client = new OSS({
					// yourRegion填写Bucket所在地域。以华东1（杭州）为例，yourRegion填写为oss-cn-hangzhou。
					region: 'oss-cn-hangzhou',
					// 从STS服务获取的临时访问密钥（AccessKey ID和AccessKey Secret）。
					accessKeyId: this.sts.credentials.accessKeyId,
					accessKeySecret: this.sts.credentials.accessKeySecret,
					// 从STS服务获取的安全令牌（SecurityToken）。
					stsToken: this.sts.credentials.securityToken,
					// 填写Bucket名称。
					bucket: 'heshangjr-development'
				})
				try {
					// 填写Object完整路径。Object完整路径中不能包含Bucket名称。
					// 您可以通过自定义文件名（例如exampleobject.txt）或文件完整路径（例如exampledir/exampleobject.txt）的形式实现将数据上传到当前Bucket或Bucket中的指定目录。
					// data对象可以自定义为file对象、Blob数据或者OSS Buffer。
					const result = client.put(fileName, data, { headers })
					// console.log(result);
				} catch (e) {
					// console.log(e);
				}
			},
			getDraftId() {
				this.draftId = localStorage.getItem('draftId')
				// console.log(this.draftId)
				if (this.draftId != '') {
					return
				}
				this.draftId = uuid.v4()
				localStorage.setItem('draftId', this.draftId)
				// let _this = this;
				// Http.get('/frontend/merchant/generateDraftId').then(result => {
				//   if (result && result.code === 200) {
				//     _this.draftId = result.data;
				//     // console.log(result)
				//   } else {
				//     _this.$message.error(result.message)
				//   }
				//   this.draftId = uuid.v4()
				// }).catch(error => {
				//   // this.$message.error('请求失败')
				//   this.draftId = uuid.v4()
				// }).finally(() => {
				//   this.draftId = uuid.v4()
				// })
			},
			async getCurrentStepData() {
				let request_uri = '/frontend/merchant/getMerchantDraft'
				let params = {
					draftId: this.draftId
				}

				let _this = this
				await Http.post(request_uri, params)
					.then(result => {
						if (result.code === 200) {
							// console.log("receiveServerData:")
							// console.log(result.data)

							// 重设所有绑定值
							_this.receiveServerData(result.data)
						} else {
							// _this.$message.error(result.message || "查询失败！")
						}
					})
					.catch(error => {
						// console.log(error)
						// _this.$message.error('请求失败')
					})
					.finally(() => {
						_this.fullscreenLoading = false
					})
			},
			receiveServerData(info) {
				this.params = [
					{
						companyCertNo: info.companyCertNo,
						companyCertPhoto: info.companyCertPhoto ? info.companyCertPhoto : '',
						companyCertType: info.companyCertType,
						companyName: info.companyName,
						merAddress: info.merAddress,
						merLogo: info.merLogo ? info.merLogo : '',
						merName: info.merName,
						merType: info.merType,
						parentId: info.parentId,
						draftId: this.draftId
					},
					{
						certificateBackPhoto: info.certificateBackPhoto ? info.certificateBackPhoto : '',
						certificateFrontPhoto: info.certificateFrontPhoto ? info.certificateFrontPhoto : '',
						certificateNo: info.certificateNo,
						certificateType: info.certificateType,
						draftId: this.draftId,
						merContactName: info.merContactName,
						merContactPhone: info.merContactPhone
					},
					{
						acctName: info.acctName,
						acctBankId: info.acctBankCode,
						acctNo: info.acctNo,
						acctPhone: info.acctPhone,
						acctCardType: info.acctCardType,
						auxiliaryMaterialsPhoto: info.auxiliaryMaterialsPhoto,
						auxiliaryMaterialsPhotoUrl: info.auxiliaryMaterialsPhotoUrl,
						draftId: this.draftId
					},

					{
						parentId: info.parentId,
						type: info.type,
						draftId: this.draftId
					}
				]
				// console.log("after")
				// console.log(this.params)
				// 设置商户类型
				if (info.merType) {
					let idx = parseInt(info.merType)
					if (idx > 0) {
						this.merValue = this.merTypes[idx - 2].text
					}
				}
				if (info.companyCertType) {
					let idx = info.companyCertType
					for (let tn of this.merCertTypes) {
						if (tn.type == idx) {
							this.merCertTypeValue = tn.text
						}
					}
				}
				if (info.acctBankCode) {
					let idx = info.acctBankCode
					for (let tn of this.banks) {
						if (tn.code == idx) {
							this.acctBank = tn.text
							this.params[2].acctBankId = tn.id
						}
					}
				}
				if (info.acctCardType) {
					let idx = info.acctCardType
					for (let tn of this.cardTypes) {
						if (tn.type == idx) {
							this.acctBankCardType = tn.text
							this.params[2].acctCardType = tn.type
						}
					}
				}

				if (info.certificateType) {
					let idx = info.certificateType
					for (let tn of this.cerTypes) {
						if (tn == idx) {
							this.certTypeValue = this.certTypeNames[this.cerTypes.indexOf(tn)]
							this.params[1].certificateType = tn
						}
					}
				} else {
					this.certTypeValue = this.certTypeNames[0]
					this.params[1].certificateType = this.cerTypes[0]
				}

				if (info.companyCertPhotoUrl) {
					this.cert = info.companyCertPhotoUrl
				}
				if (info.auxiliaryMaterialsPhotoUrl) {
					this.prof = info.auxiliaryMaterialsPhotoUrl
				}
				this.fileListIdCert = []
				if (info.certificateFrontPhotoUrl) {
					this.certFront = info.certificateFrontPhotoUrl
					this.fileListIdCert.push(this.certFront)
				}
				if (info.certificateBackPhotoUrl) {
					this.certBack = info.certificateBackPhotoUrl
					this.fileListIdCert.push(this.certBack)
				}
				if (info.companyCertPhotoUrl) {
					this.cert = info.companyCertPhotoUrl
				}

				if (info.merLogoUrl) {
					this.logo = info.merLogoUrl
				}

				if (info.parentId) {
					this.params[0].parentId = info.parentId
					// localStorage.setItem("templeId", info.parentId)
					this.templeId = info.parentId
				}

				// console.log("this.params:")
				// console.log(this.params)
			},
			getBankList() {
				let _this = this
				Http.get('/frontend/merchant/getBankList')
					.then(result => {
						if (result && result.code === 200) {
							if (result.data !== null) {
								_this.banks = [{ text: '' }]
								_this.acctBank = ''
								let data = result.data
								for (let bn of data) {
									bn.text = bn.name

									// console.log(bn)
									_this.banks.push(bn)

									if (_this.params[2].acctBankId == bn.code) {
										_this.acctBank = bn.text
									}
								}
							}
						} else {
							_this.$message.error(result.message)
						}
					})
					.catch(error => {
						_this.$message.error('请求失败')
					})
					.finally(() => {
						_this.fullscreenLoading = false
					})
			},
			getSTSInfo() {
				// this.sts = {
				//   requestId: "4A69AA02-3541-5FBB-AB65-CDFC078172E7",
				//   credentials: {
				//     securityToken: "CAIS+QF1q6Ft5B2yfSjIr5bTH4n6r51n3vCnZE/HjHoWO/ZnuYH+0Dz2IH1PfXRtBeEWv/k+lW9X5/4alqFWRoVORUXCcZOaF37bdkTzDbDasumZsJYm6vT8a0XxZjf/2MjNGZabKPrWZvaqbX3diyZ32sGUXD6+XlujQ/br4NwdGbZxZASjaidcD9p7PxZrrNRgVUHcLvGwKBXn8AGyZQhKwlMh1Tsls/XumZLDtEqG0g3Ap7VL99irEP+NdNJxOZpzadCx0dFte7DJuCwqsEURrfor0P0UqGqW747CUgEJ+W+GPvHU+9tgLhU8fbQhXrRDqPXseVjQAE8hIe4agAGXTWBuEN5nu2Cnnsmo9APEKMbCS0W1kOVYz/S2uYFvOsdGgUcMLVpRq/HQHuN8DxTIDRazJphn+SBNqNmY0UJtd9mGOmD9ZvbpoRmCDyMXnXCT+lJAuv9s6U61LPXus2xd1nexiW5gHHHlzI+r0YaKQpwCoEdQzdWsjw2rBapdnA==",
				//     accessKeySecret: "Fi6YeVmjJAK67mpLzqExbTmmVDnSREdRfW2w1t2u5Lpy",
				//     accessKeyId: "STS.NUfT3NBBFi2LfivhzC7yKVDU2",
				//     expiration: "2022-02-15T08:29:49Z"
				//   },
				//   assumedRoleUser: {

				//   }
				// }
				let _this = this
				Http.get('/frontend/aliyun/sts')
					.then(result => {
						if (result && result.code === 200) {
							_this.sts = result.data
							// console.log(result)
						} else {
							_this.$message.error(result.message)
						}
					})
					.catch(error => {})
					.finally(() => {})
			},
			getTempleList() {
				let _this = this
				Http.get('/frontend/merchant/getTempleList')
					.then(result => {
						if (result && result.code === 200) {
							if (result.data !== null) {
								_this.temples = [{ text: '' }]
								_this.temple = ''
								// console.log(result.data)
								let data = result.data
								for (let bn of data) {
									bn.text = bn.merName

									_this.temples.push(bn)

									if (_this.params[2].acctBankId == bn.code) {
										_this.acctBank = bn.text
									}
								}
							}
						} else {
							_this.$message.error(result.message)
						}
					})
					.catch(error => {
						_this.$message.error('请求失败')
					})
					.finally(() => {
						_this.fullscreenLoading = false
					})
			},
			getTempleInfo() {
				let _this = this
				let templeId = this.$route.query.merchantId
				if (templeId == undefined) {
					// this.$message.error("缺少寺庙信息!")
					templeId = this.templeId
					localStorage.setItem('templeId', templeId)
				} else {
					templeId = templeId + ''
					this.params[0].parentId = templeId
					localStorage.setItem('templeId', templeId)
				}

				// console.log("templeId")
				// 获取名称
				let merId = localStorage.getItem('templeId')

				if (merId == undefined) {
					this.$message.error('缺少请求参数!')
				}
				Http.get('frontend/merchant/getMerchant?merchantId=' + merId)
					.then(result => {
						if (result && result.code === 200) {
							// console.log(result)
							if (result.data.merName) {
								_this.merchantName = result.data.merName
							}
						} else {
							if (result.message) {
								_this.$message.error(result.message)
							} else {
								_this.$message.error('请求失败')
							}
						}
					})
					.catch(error => {
						_this.$message.error(error)
					})
					.finally(() => {
						_this.fullscreenLoading = false
					})
			},
			async getAllData() {
				await this.getCurrentStepData()
				this.getTempleInfo()
			}
		},
		mounted() {
			let _this = this
			_this.fileListCert = []
			_this.fileListLogo = []
			this.merType = '2'
			this.merValue = '企业'
			this.merCertTypeValue = '营业执照'
			this.params[0].companyCertType = 'U'

			this.getDraftId()

			this.getAllData()

			this.getSTSInfo()
			this.getBankList()
		}
	}
</script>

<style scoped>
	.top {
		margin-top: 32px;
	}

	.user_logo {
		height: 32px;
	}

	.button-area {
		margin-top: calc(100vh - 128px - 120px - 64px);
		/*减去头部，表单以及自身高度*/
	}

	.button-area-3 {
		margin-top: calc(100vh - 128px - 120px - 520px);
		/*减去头部，表单以及自身高度*/
	}

	.tips {
		width: 92vw;
		margin-top: 20px;
		margin-left: 4vw;
		height: 78px;
		background: #fffcf2;
		border: 1px solid rgba(166, 132, 31, 0.28);
		border-radius: 8px;
	}

	.tip-text {
		margin-left: 12px;
		margin-right: 12px;
		margin-top: 16px;
		line-height: 24px;
		font-size: 14px;
	}

	.tips img {
		width: 20px;
		height: 20px;
		margin-top: -4px;
	}

	/*系统自定义部分*/
	/deep/ ul {
		padding-left: 0px;
	}

	/*vant自定义部分*/
	/deep/ .van-uploader__upload {
		width: 32px;
		height: 32px;
	}

	/deep/ .van-image.van-uploader__preview-image {
		width: 32px;
		height: 32px;
	}

	/deep/ .van-button--primary {
		background-color: #f1bd20;
	}

	/*element自定义部分*/

	/deep/ .el-step__title.is-finish {
		color: #f1bd20;
	}

	/deep/ .el-step__title {
		font-size: 12px;
		font-weight: normal;
		color: #d5d5d5;
	}

	/deep/ .el-step__head.is-finish > .el-step__icon.is-text {
		background-color: #f1bd20;
		border: none;
		color: white;
	}

	/deep/ .el-step__head.is-wait > .el-step__icon.is-text {
		background-color: #d5d5d5;
		border: none;
		color: white;
	}

	/deep/ .el-step__head.is-process > .el-step__icon.is-text {
		background-color: #d5d5d5;
		border: none;
		color: white;
	}

	/deep/ .van-field__control:disabled {
		color: #323233;
		-webkit-text-fill-color: #323233;
	}

	/deep/ .van-field--disabled .van-field__label {
		color: #323233;
	}

	/deep/ .van-field__label {
		width: 6.5em;
	}
</style>
